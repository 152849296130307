import briefcase from '~/assets/icons/briefcase.svg';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '~/components';
import CustomModal from '~/components/CustomModal';
import { useAuth } from '~/hooks';
import { getAuth } from '~/repositories/auth';
import { getDomain } from '~/utils';
import { copyText } from '~/utils/copyText';
import {
  Container,
  Content,
  ContentButton,
  ContentButtonWrapper,
  ContentImage,
  ContentParagraph,
  ContentTitle,
  Title,
} from './styles';

const DriverActive = () => {
  const { user } = useAuth();
  const { userId } = getAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [openModalVisible, setOpenModalVisible] = useState(false);

  const driverStatus = user.driverAndPassenger;

  useEffect(() => {
    if (!driverStatus) return navigate(`/${getDomain()}`);
    if (driverStatus === 'NullDriver') return navigate(`/${getDomain()}`);
    if (driverStatus === 'CheckDriver')
      return navigate(`${getDomain()}/cadastro/veiculo/analise`);
    return setIsLoading(false);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Title label="Quero dirigir" />
        <Content>
          <ContentImage src={briefcase} alt="Escudo roxo" />
          <ContentTitle>Seus dados foram aprovados</ContentTitle>
          <ContentParagraph>
            Baixe ou acesse o Driver App para receber chamados e faturar alto
          </ContentParagraph>
          <ContentButtonWrapper>
            <ContentButton
              label="Baixar o Driver App"
              color="white"
              className="download"
              onClick={() => {
                copyText(userId);
                setDownloadModalVisible(true);
              }}
            />
          </ContentButtonWrapper>
        </Content>
      </Container>
      <CustomModal
        visible={downloadModalVisible}
        label="Baixe o Driver app! 100% Seguro."
        onClose={() => setDownloadModalVisible(false)}
        buttonLabel="Ir para a loja"
        action={() => {
          window.open(
            'https://drive.google.com/file/d/1LIH4DC6qBFEVbwsz9hBO7g6gYVmSy-mN/view',
            '_blank'
          );
        }}
      />
      <CustomModal
        visible={openModalVisible}
        label="Seu ID MOTORISTA foi copiado. Quando necessário é só colar no campo solicitado."
        onClose={() => setOpenModalVisible(false)}
        buttonLabel="Abrir o App"
        action={() => {}}
      />
    </>
  );
};

export default DriverActive;
